.splash-container.refund {
  max-width: 400px;
}

.flex-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 20px 0;
}

.flex-container-button {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}

.action-buttons {
  margin: 0 10px;
}

.action-buttons button {
  border: 1px solid #4b4b4b;
}
