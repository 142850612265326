@font-face {
  font-family: "Glyphicons Halflings";
  src: url("/assets/lib/bootstrap/dist/fonts/glyphicons-halflings-regular.eot");
  src: url("/assets/lib/bootstrap/dist/fonts/glyphicons-halflings-regular.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/lib/bootstrap/dist/fonts/glyphicons-halflings-regular.woff2")
      format("woff2"),
    url("/assets/lib/bootstrap/dist/fonts/glyphicons-halflings-regular.woff")
      format("woff"),
    url("/assets/lib/bootstrap/dist/fonts/glyphicons-halflings-regular.ttf")
      format("truetype"),
    url("/assets/lib/bootstrap/dist/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular")
      format("svg");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/Roboto-Regular/Roboto-Regular.eot");
  src: url("../fonts/Roboto-Regular/Roboto-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Roboto-Regular/Roboto-Regular.ttf") format("truetype"),
    url("../fonts/Roboto-Regular/Roboto-Regular.woff") format("woff"),
    url("../fonts/Roboto-Regular/Roboto-Regular.svg#Roboto-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../fonts/Roboto-Bold/Roboto-Bold.eot");
  src: url("../fonts/Roboto-Bold/Roboto-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Roboto-Bold/Roboto-Bold.ttf") format("truetype"),
    url("../fonts/Roboto-Bold/Roboto-Bold.woff") format("woff"),
    url("../fonts/Roboto-Bold/Roboto-Bold.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Black";
  src: url("../fonts/Roboto-Black/Roboto-Black.eot");
  src: url("../fonts/Roboto-Black/Roboto-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Roboto-Black/Roboto-Black.ttf") format("truetype"),
    url("../fonts/Roboto-Black/Roboto-Black.woff") format("woff"),
    url("../fonts/Roboto-Black/Roboto-Black.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../fonts/Roboto-Medium/Roboto-Medium.eot");
  src: url("../fonts/Roboto-Medium/Roboto-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Roboto-Medium/Roboto-Medium.ttf") format("truetype"),
    url("../fonts/Roboto-Medium/Roboto-Medium.woff") format("woff"),
    url("../fonts/Roboto-Medium/Roboto-Medium.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Light";
  src: url("../fonts/Roboto-Light/Roboto-Light.eot");
  src: url("../fonts/Roboto-Light/Roboto-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Roboto-Light/Roboto-Light.ttf") format("truetype"),
    url("../fonts/Roboto-Light/Roboto-Light.woff") format("woff"),
    url("../fonts/Roboto-Light/Roboto-Light.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-LightItalic";
  src: url("../fonts/Roboto-LightItalic/Roboto-LightItalic.eot");
  src: url("../fonts/Roboto-LightItalic/Roboto-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Roboto-LightItalic/Roboto-LightItalic.ttf") format("truetype"),
    url("../fonts/Roboto-LightItalic/Roboto-LightItalic.woff") format("woff"),
    url("../fonts/Roboto-LightItalic/Roboto-LightItalic.svg#Roboto-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Thin";
  src: url("../fonts/Roboto-Thin/Roboto-Thin.eot");
  src: url("../fonts/Roboto-Thin/Roboto-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Roboto-Thin/Roboto-Thin.ttf") format("truetype"),
    url("../fonts/Roboto-Thin/Roboto-Thin.woff") format("woff"),
    url("../fonts/Roboto-Thin/Roboto-Thin.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
