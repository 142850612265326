.modal-content {
  box-shadow: none;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .modal-sm {
    width: 500px;
  }
}
