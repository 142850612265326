/* Example Styles for React Tags*/

div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */

div.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
  padding-top: 15px;
  padding-left: 5px;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  border: 1px solid #eee;
  border-width: 1px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px 12px;
  font-size: 12px;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  height: 32px;
  font-family: 'Roboto', Arial, sans-serif;
}

/* Styles for selected tags */

div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  color: #fff;
  background-color: #4285f4;
  border-color: #4285f4;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  cursor: move;
  border-radius: 2px;
  font-weight: bold;
  font-family: 'Roboto', Arial, sans-serif;
}

div.ReactTags__selected a.ReactTags__remove {
  color: #fff;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */

div.ReactTags__suggestions {
  position: absolute;
}

div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 218px;
  padding-left: 0px;
  font-family: 'Roboto', Arial, sans-serif;
}

div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

div.ReactTags__suggestions li mark {
  background: none;
  font-weight: 600;
  cursor: pointer;
}
